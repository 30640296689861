// React Imports
import React from "react";
//import { Link } from "react-router-dom";

// Style Imports
import "../assets/styles/PortfolioCard.scss";

// Component Imports

// Icon Imports

function PortfolioCard({ client, type, link }) {
    // Generate a class name for each work entry based on the client name
    const workEntryClassName = client.toLowerCase().replace(/ /g, "-");

    console.log({ link });
    return (
        <div
            className={`portfolio-card-container work-entry ${workEntryClassName}`}
        >
            <a href={link} target="_blank" rel="noreferrer">
                <div className="portfolio-card">
                    <h3>{type}</h3>
                    <h2>{client}</h2>
                </div>
            </a>
        </div>
    );
}

export default PortfolioCard;
