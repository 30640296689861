// React Imports
import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

// Style Imports
import "../assets/styles/Navigation.scss";

// Component Imports

// Image Imports
import logo from "../assets/images/logo.webp";

function Navigation() {
    // Scroll to add a background color
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = 100; // 100vh
            const isScrolled = window.scrollY > offset;
            setScrolled(isScrolled);
        };

        // Add the event listener when the component mounts
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`navigation-container ${scrolled ? "scrolled" : ""}`}>
            <div className="navigation">
                <div className="navigation-content">
                    <img className="logo" src={logo} alt="Dalton Casey logo" />
                    <nav>
                        <ScrollLink
                            className="nav-link"
                            to="work"
                            smooth={true}
                            duration={1500}
                        >
                            Work
                        </ScrollLink>
                        <ScrollLink
                            className="nav-link"
                            to="contact"
                            smooth={true}
                            duration={1000}
                        >
                            Contact
                        </ScrollLink>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Navigation;
