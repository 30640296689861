// React Imports
import React from "react";

// Style Imports
import "../assets/styles/Home.scss";
import WelcomeSection from "../components/WelcomeSection";
import WorkSection from "../components/WorkSection";
import ContactSection from "../components/ContactSection";

// Component Imports

function Home() {
    return (
        <>
            <WelcomeSection />
            <WorkSection />
            <ContactSection />
        </>
    );
}

export default Home;
