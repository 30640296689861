// React Imports
import React from "react";

// Style Imports
import "../assets/styles/WorkSection.scss";

// Component Imports
import PortfolioCard from "./PortfolioCard";

// JSON data
import workData from "../data/work.json";

function WorkSection() {
    return (
        <div className="work-section-container" id="work">
            <div className="work-section">
                <div className="work-section-content">
                    <h1>Work</h1>
                    <div className="work-section-grid">
                        {workData.works.map((work, index) => (
                            <PortfolioCard
                                key={index}
                                client={work.client}
                                type={work.type}
                                link={work.link}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkSection;
