// React Imports
import React from "react";
//import { Link } from "react-router-dom";

// Style Imports
import "../assets/styles/WelcomeSection.scss";

// Component Imports

// Icon Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

function WelcomeSection() {
    return (
        <div className="welcome-section-container">
            <div className="welcome-section">
                <div className="welcome-section-content">
                    <h1>Dalton Casey</h1>
                    <hr />
                    <h2>Web Designer &amp; Developer</h2>
                    <p>
                        With over 10 years of experience, I specialize in small
                        to medium sized business websites and branding of all
                        trades. I am currently
                        <strong> available for Freelance Work</strong>. Take a
                        look at my projects and let's talk!
                    </p>
                    <FontAwesomeIcon
                        className="scroll-down-icon"
                        icon={faArrowDown}
                    />
                </div>
            </div>
        </div>
    );
}

export default WelcomeSection;
