// React Imports
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Style Imports
import "./App.scss";

// Component Imports
import Home from "./pages/Home";
import Navigation from "./components/Navigation";

function App() {
    // Fade app into view
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className={`app ${isVisible ? "visible" : ""}`}>
            <Router>
                <Navigation />

                <Routes>
                    <Route path="/" exact element={<Home />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
