// React Imports
import React from "react";

// Style Imports
import "../assets/styles/ContactSection.scss";

// Component Imports

// Icon Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function ContactSection() {
    return (
        <div className="contact-container">
            <div className="contact">
                <div className="contact-content">
                    <h1>Contact</h1>
                    <p>
                        <span>I am available for freelance work</span>. Please,
                        feel free to reach out to me to discuss a project you
                        had in mind. I'll get back to you as soon as possible. I
                        look forward to working with you!
                    </p>
                    <a href="mailto:hello@daltoncasey.dev">
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className="contact-icon"
                        />
                        hello(at)daltoncasey.dev
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactSection;
